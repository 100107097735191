var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-wrapper',[_c('div',{staticClass:"space-y-4"},[_c('div',{staticClass:"flex justify-end gap-2"},[_c('base-select',{attrs:{"expand":false,"shadow":false,"options":[
          {
            key: 'all',
            label: 'Status',
            value: 'Diproses Gudang Pengeluaran,Persiapan',
          },
          {
            key: 'draft',
            label: 'Diproses Gudang Pengeluaran',
            value: 'Diproses Gudang Pengeluaran',
          },
          { key: 'valid', label: 'Persiapan', value: 'Persiapan' },
        ]},on:{"change":_vm.loadOrders},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}}),_c('base-input',{attrs:{"type":"search","shadow":false,"placeholder":"Cari Kode","debounce":""},on:{"native-input":_vm.loadOrders},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('datatable',{attrs:{"scroll-x":false,"columns":[
        { id: 'code', name: 'Nomor Faktur' },
        { id: 'destination_office_code', name: 'Kode Stockist' },
        { id: 'destination_office_name', name: 'Nama Pemilik' },
        { id: 'warehouse', name: 'Kode Gudang' },
        { id: 'area', name: 'Wilayah Harga' },
        {
          id: 'total_price',
          name: 'Total Penjualan',
          theadClass: 'text-right',
        },
        {
          id: 'warehouse_status',
          name: 'Status Pengeluaran',
          theadClass: 'text-center',
        },
      ],"total":_vm.orders.meta.page.total,"perPage":_vm.orders.meta.page.perPage,"currentPage":_vm.orders.meta.page.currentPage,"meta":_vm.orders.meta,"cursor":""},on:{"pagechanged":_vm.onPageChange},scopedSlots:_vm._u([{key:"tbody",fn:function({ classes }){return _vm._l((_vm.orders.data),function(order,index){return _c('tr',{key:index,class:[classes.tr, 'cursor-pointer bg-white hover:bg-green-100'],on:{"click":function($event){return _vm.$router.push({
              name: 'gudang.pengeluaran.restock-barang-ke-stockist-diambil.detail',
              params: { id: order.relationships.order.data.id },
            })}}},[_c('td',{class:[classes.td, 'space-y-1']},[_c('p',{staticClass:"font-bold text-gray-900"},[_vm._v(" "+_vm._s(order.attributes.origin_code)+" ")]),_c('p',{staticClass:"text-xs text-gray-500"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(order.attributes.updatedAt))+" ")])]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm.getSingleIncluded( _vm.orders, order.relationships['destination-office'].data.id ).attributes.code)+" ")]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm.getSingleIncluded( _vm.orders, order.relationships['destination-office'].data.id ).attributes.name)+" ")]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm.getSingleIncluded( _vm.orders, order.relationships['destination-warehouse'].data.id ).attributes.code)+" / "+_vm._s(_vm.getSingleIncluded( _vm.orders, order.relationships['origin-warehouse'].data.id ).attributes.code)+" ")]),_c('td',{class:classes.td},[_vm._v(" "+_vm._s(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.area_name)+" ")]),_c('td',{class:[classes.td, 'text-right']},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.getSingleIncluded(_vm.orders, order.relationships.order.data.id) .attributes.grand_total_price))+" ")]),_c('td',{class:[classes.td, 'text-center']},[_c('base-badge',{attrs:{"color":"indigo"}},[_vm._v(_vm._s(order.attributes.warehouse_status))])],1)])})}}])})],1),(_vm.loading)?_c('loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }